import { createStore } from 'vuex'
import { 
  login as memberLogin, changeEmail as changeMemberEmail, 
  changePhoneNumber as changeMemberPhoneNumber,
  getShippingAddresses as _getShippingAddresses,
  deleteShippingAddress as _deleteShippingAddress,
  addShippingAddress as _addShippingAddress,
  updateShippingAddress as _updateShippingAddress,
  getProducts as _getProducts
} from '@/api'

let isShippingAddressesLoaded = false
let isProductsLoaded = false

export default createStore({
  state: {
    isAuthenticated: false,
    memberDetails: {},
    shippingAddresses: null,
    products: null,
  },
  getters: {
  },
  mutations: {
    setAuthenticated(state, payload) {
      state.isAuthenticated = true
      Object.assign(state.memberDetails, payload)
      isShippingAddressesLoaded = false
      state.shippingAddresses = null
      isProductsLoaded = false
      state.products = null
    },
    setLogout(state) {
      state.isAuthenticated = false
      state.memberDetails = {}
      isShippingAddressesLoaded = false
      state.shippingAddresses = null
      isProductsLoaded = false
      state.products = null
    },
    updateMemberDetails(state, payload) {
      Object.assign(state.memberDetails, payload)
    },
    setShippingAddresses(state, payload) {
      isShippingAddressesLoaded = true
      state.shippingAddresses = payload
    },
    addShippingAddress(state, payload) {
      state.shippingAddresses.push(payload)
    }, 
    updateShippingAddress(state, payload) {
      for (let sa of state.shippingAddresses) {
        if (sa.id === payload.id) {
          Object.assign(sa, payload)
          break;
        }        
      }
    },
    deleteShippingAddress(state, payload) {
      for (let index in state.shippingAddresses) {
        if (state.shippingAddresses[index].id === payload.id) {
          state.shippingAddresses.splice(index, 1)
          break;
        }        
      }
    },
    setProducts(state, payload) {
      let products = {}
      for (let i in payload) products[payload[i].productId] = payload[i]
      state.products = products
      isShippingAddressesLoaded = true
    }    
  },
  actions: {
    login({ state, commit }, config) {
      memberLogin(Object.assign({}, config, {success(data) {
        commit('setAuthenticated', data)
      }}))
    },
    changeEmail({ state, commit }, config) {
      changeMemberEmail(Object.assign({}, config, {success(data) {
        commit('updateMemberDetails', data)
        config.success && config.success(data)
      }}))
    },
    changePhoneNumber({ state, commit }, config) {
      changeMemberPhoneNumber(Object.assign({}, config, {success(data) {
        commit('updateMemberDetails', data)
        config.success && config.success(data)
      }}))
    },
    loadShippingAddresses({ state, commit }, config) {
      if (isShippingAddressesLoaded) {
        config?.success && config.success(state.shippingAddresses)
      } else {
        _getShippingAddresses(Object.assign({}, config, {success(data) {
          commit('setShippingAddresses', data)
          config?.success && config.success(data)
        }}))
      }
    },
    addShippingAddress({ state, commit }, config) {
      _addShippingAddress(Object.assign({}, config, {success(data) {
        commit('addShippingAddress', data)
        config.success && config.success(data)
      }}))
    },    
    updateShippingAddress({ state, commit }, config) {
      console.info(config)
      _updateShippingAddress(Object.assign({}, config, {success(data) {
        commit('updateShippingAddress', config.data)
        config.success && config.success()
      }}))
    },    
    deleteShippingAddress({ state, commit }, config) {
      _deleteShippingAddress(Object.assign({}, config, {success(data) {
        commit('deleteShippingAddress', data)
        config.success && config.success(data)
      }}))
    },
    loadProducts({ state, commit }, config) {
      if (isProductsLoaded) {
        config.success && config.success(state.products)
      } else {
        _getProducts(Object.assign({}, config, {success(data) {
          commit('setProducts', data)
          config.success && config.success(state.products)
        }}))
      }
    },
  },
})

