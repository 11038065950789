<template>
  <div v-show="visible" class="vpt-popup-menu" :class="{sticky: sticky}"  @click="visible=false" @touchmove.prevent.stop @wheel.prevent.stop>
    <div ref="closeButton" class="close-button" @click.capture="visible=false">
      <i class="iconfont">&#xeaf2;</i>
    </div>
    <div ref="contentWrapper" class="content-wrapper" @click.capture="visible=false">
      <slot></slot>
    </div>
  </div>
</template>
 
<script setup>
import { ref } from 'vue'

const props = defineProps({
  sticky: {
    type: Boolean,
    default: false
  }    
})

const visible = ref(false)
const closeButton = ref(null)
const contentWrapper = ref(null)

function open(dockEl) {
  const rect = dockEl.getBoundingClientRect();

  closeButton.value.style.left = rect.left + 'px'
  closeButton.value.style.top = rect.top + 'px'
  closeButton.value.style.width = rect.width + 'px'
  closeButton.value.style.height = rect.height + 'px'

  contentWrapper.value.style.top = (rect.bottom + 2) + 'px'

  if (props.sticky) {
    contentWrapper.value.style.right = (document.documentElement.clientWidth - rect.right) + 'px'
  } else {
    contentWrapper.value.style.left = '0'
    contentWrapper.value.style.right = '0'
  }

  visible.value = true
}

defineExpose({
  open,
})

</script>
 
<style scoped>

.vpt-popup-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 998;
  .close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    background: rgb(0, 0, 0, 1);
    color: white;
    .iconfont {
      font-size: 3rem;
    }    
  }
  .content-wrapper {
    position: fixed;
    z-index: 999;
    background: rgb(0, 0, 0, 0);
  }
  &.sticky {
    background: rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width:1680px) {
}

@media only screen and (max-width:1440px) {
}

@media only screen and (max-width:1280px) {
}

@media only screen and (max-width:1024px) {
}

@media only screen and (max-width:720px) {
}

</style>